<template>
  <v-app>
    <!--  -->
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <base-material-card
            title="Upload Url"
            icon="mdi-youtube"
          >
            <!--  -->
            <v-card>
              <v-form>
                <div>
                  <div
                    v-for="(data, i) in yt.data.url"
                    :key="i"
                    class="d-flex flex-no-wrap justify-start align-start"
                  >
                    <div class="d-flex flex-column align-start flex-grow-1">
                      <v-form style="width: 100%">
                        <v-text-field
                          v-model="yt.data.url[i]"
                          label="Masukan Url"
                          :placeholder="'Masukan Url ke-' + (i + 1)"
                        />
                      </v-form>
                    </div>
                    <div class="d-flex align-center align-self-sm-center">
                      <v-tooltip
                        bottom
                        color="blue"
                      >
                        <!--  -->
                        <template v-slot:activator="{ on, attrs }">
                          <!--  -->
                          <v-btn
                            icon
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            @click="addUrl"
                          >
                            <v-icon>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>send</span>
                      </v-tooltip>
                    </div>
                    <div class="d-flex align-center align-self-sm-center">
                      <v-tooltip
                        bottom
                        color="red"
                      >
                        <!--  -->
                        <template v-slot:activator="{ on, attrs }">
                          <!--  -->
                          <v-btn
                            icon
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            @click="del(i)"
                          >
                            <v-icon>
                              mdi-close-octagon
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <div>
                  <v-select
                    v-model="items"
                    :items="type_items"
                    label="Pilih Status"
                    item-text="name"
                    item-value="id"
                  >
                    <!--  -->
                  </v-select>
                </div>
                <div class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    @click="sendUrl"
                  >
                    Buat
                  </v-btn>
                </div>
              </v-form>
            </v-card>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'Play',
    data: () => ({
      yt: {
        meta: {},
        data: {
          url: [''],
        },
      },
      url: '',
      items: '',
      type_items: ['Playlist', 'Live'],
    }),
    mounted () {},
    methods: {
      addUrl () {
        this.yt.data.url.push('')
      },
      del (index) {
        if (index > 0 || this.yt.data.url.length > 1) {
          this.yt.data.url.splice(index, 1)
        }
      },
      sendUrl () {
        axios
          .post('/v1/media/video', {
            url: this.yt.data.url,
            type: this.items,
          })
          .then(response => {
            if (response.data.meta.status) {
              console.log(response.data)
              this.$router.push({ path: '/play' })
              this.$toast.success('Berhasil Di Buat', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            }
          })
          .catch(e => {
            console.log(e)
          })
      // this.yt.data.map(x => {
      //   console.log(x.url)
      // })
      },
    },
  }
</script>

<style></style>
